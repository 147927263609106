<template>
	<div class="app-container" v-loading="loading">
		<el-header class="new-header">
			<div class="new-header-left">计费账户列表</div>
			<div class="new-header-right">
				<el-button class="btn" @click="dialogForAddAccount = true" type="primary" plain>新建
				</el-button>
			</div>
		</el-header>
		<el-form :inline="true" :model="searchForm" ref="searchForm" @submit.native.prevent="onSubmit">
			<el-form-item label="计费账户">
				<el-input v-model="searchForm.acctName" placeholder="计费账户" size="small" clearable
					@clear="searchForm.acctName = null"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" icon="search" size="small">搜索
				</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="list" border style="width: 100%" ref="list" stripe size="small">
			<el-table-column prop="acctName" label="账户名称" align="center"></el-table-column>
			<el-table-column prop="balance" label="账户余额" align="center"></el-table-column>
			<el-table-column prop="minimumCost" label="当月最低消费" align="center"></el-table-column>
			<el-table-column prop="usedCost" label="当月已用" align="center"></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-tooltip placement="top" content="编辑">
						<el-button type="text" @click="handleInitModifyAccount(scope.row)" size="small">
							编辑
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination-block">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pageVo.current" :page-sizes="[2, 10, 20, 50, 100]" :page-size="pageVo.size"
				layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
			</el-pagination>
		</div>
		<el-dialog title="新建计费账户" :visible.sync="dialogForAddAccount">
			<el-form ref="addForm" :model="addForm" label-width="150px" :rules="rules">
				<el-row type="flex" justify="center">
					<el-col :span="12">
						<el-form-item label="计费账户名称:" prop="ruleName">
							<el-input v-model="addForm.acctName" placeholder="请输入计费账户名称"></el-input>
						</el-form-item>
						<el-form-item label="账户余额:" prop="balance">
							<el-input-number controls-position="right" v-model="addForm.balance" placeholder="账户余额"
								style="width: 100%"></el-input-number>
						</el-form-item>
						<el-form-item label="当月最低消费:" prop="minimumCost">
							<el-input-number controls-position="right" v-model="addForm.minimumCost" placeholder="当月最低消费"
								style="width: 100%"></el-input-number>
						</el-form-item>
						<el-form-item label="当月已用:" prop="usedCost">
							<el-input-number controls-position="right" v-model="addForm.usedCost" placeholder="当月已用"
								style="width: 100%"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="info" @click="dialogForAddAccount = false">取消</el-button>
				<el-button type="primary" @click="handleAddAccount">确认</el-button>
			</div>
		</el-dialog>
		<el-dialog title="编辑计费账户" :visible.sync="dialogForModifyAccountRule">
			<el-form ref="modifyForm" :model="modifyForm" label-width="150px" :rules="rules">
				<el-row type="flex" justify="center">
					<el-col :span="12">
						<el-form-item label="计费账户名称:" prop="ruleName">
							<el-input v-model="modifyForm.acctName" placeholder="请输入计费账户名称"></el-input>
						</el-form-item>
						<el-form-item label="账户余额:" prop="balance">
							<el-input-number controls-position="right" v-model="modifyForm.balance" placeholder="账户余额"
								style="width: 100%"></el-input-number>
						</el-form-item>
						<el-form-item label="当月最低消费:" prop="minimumCost">
							<el-input-number controls-position="right" v-model="modifyForm.minimumCost" placeholder="当月最低消费"
								style="width: 100%"></el-input-number>
						</el-form-item>
						<el-form-item label="当月已用:" prop="usedCost">
							<el-input-number controls-position="right" v-model="modifyForm.usedCost" placeholder="当月已用"
								style="width: 100%"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="info" @click="dialogForModifyAccountRule = false">取消</el-button>
				<el-button type="primary" @click="handleModifyAccountRule">确认</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		createBillingAcct,
		deleteApplyState,
		editApplyState,
		listBillingAcctByPage,
		updateBillingAcct,
	} from '../../api';

	export default {
		name: '',
		data() {
			return {
				dialogForAddAccount: false,
				dialogForModifyAccountRule: false,
				searchForm: {
					adminId: this.$store.getters.adminId,
					acctName: null,
				},
				addForm: {
					acctName: '',
					balance: '',
					minimumCost: '',
					usedCost: '',
					adminId: this.$store.getters.adminId,
				},
				modifyForm: {
					acctId: '',
					acctName: '',
					balance: '',
					minimumCost: '',
					usedCost: '',
					adminId: this.$store.getters.adminId,
				},
				list: [],
				pageVo: {
					current: 1,
					size: 10,
				},
				totalCount: 0,
				loading: false,
				lineList: [],
				rules: {
					acctName: [{ required: true, message: '请输入计费账户名称', trigger: 'blur' }],
					balance: [{ required: true, message: '请输入账户余额', trigger: 'blur' }],
					minimumCost: [{ required: true, message: '请输入当月最低消费', trigger: 'blur' }],
					usedCost: [{ required: true, message: '请输入当月已用', trigger: 'blur' }],
				},
			};
		},
		created() {
			console.log('created==========');
			this.loadAccountList();
		},
		methods: {
			handleDeleteApply(row) {
				if (row.applyId) {
					this.$confirm(`确定删除应用${row.applyName}吗？`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}).then(() => {
						deleteApplyState({
							applyId: row.applyId,
							adminId: this.$store.getters.adminId,
						}).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '删除成功',
								});
								this.loadAccountList();
							}
						});
					});
				}
			},
			handleChangeApplyState(row) {
				let param = {
					applyId: row.applyId,
					state: row.state,
					adminId: this.$store.getters.adminId,
				};
				editApplyState(param).then((data) => {
					if (data) {
						let message =
							row.state === 1 ? `启用应用${row.applyName}成功` : `停用应用${row.applyName}成功`;
						this.$notify({
							type: 'success',
							title: '成功',
							message: message,
						});
					}
				});
			},
			handleAddAccount() {
				this.$refs.addForm.validate((valid) => {
					if (valid) {
						let param = {
							...this.addForm,
						};
						createBillingAcct(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '创建计费账户成功',
								});
								this.dialogForAddAccount = false;
								this.$refs.addForm.resetFields();
								this.loadAccountList();
							}
						});
					}
				});
			},
			handleInitModifyAccount(row) {
				console.log('row========', row);
				Object.assign(this.modifyForm, row);
				this.dialogForModifyAccountRule = true;
			},
			handleModifyAccountRule() {
				this.$refs.modifyForm.validate((valid) => {
					if (valid) {
						let param = {
							...this.modifyForm,
						};
						updateBillingAcct(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '编辑计费账户成功',
								});
								this.dialogForModifyAccountRule = false;
								this.loadAccountList();
							}
						});
					}
				});
			},
			onSubmit() {
				this.pageVo.current = 1;
				this.loadAccountList();
			},
			handleSizeChange(val) {
				this.pageVo.size = val;
				this.loadAccountList();
			},
			handleCurrentChange(val) {
				this.pageVo.current = val;
				this.loadAccountList();
			},
			loadAccountList() {
				let params = {
					...this.pageVo,
					params: this.searchForm,
				};
				this.loading = true;
				listBillingAcctByPage(params).then((data) => {
					console.log(data);
					this.list = data.records;
					this.totalCount = data.total;
					this.loading = false;
				});
			},
		},
	};
</script>
<style lang="scss"></style>
`1111
`
